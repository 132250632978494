<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form ref="form" lazy-validation @submit.prevent="buscar">
          <v-row>
            <v-col cols="6" sm="5" md="5" lg="5">
              <v-text-field
                label="Data Inicio"
                ref="dataInicio"
                outlined
                dense
                v-model="form.dataInicio"
                :rules="[rules.required]"
                v-mask="'##/##/####'"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="5" md="5" lg="5">
              <v-text-field
                label="Data Início"
                ref="dataInicio"
                outlined
                dense
                v-model="form.dataFim"
                :rules="[rules.required]"
                v-mask="'##/##/####'"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" align="right">
              <v-btn
                dark
                depressed
                color="primary"
                type="submit"
                :loading="loading"
                :disabled="loading"
                >Buscar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="contas"
        :loading="loading"
        :options.sync="options"
        :server-items-length="pageable.totalElements"
        hide-default-footer
        dense
      >
        <!-- <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> Contas a pagar</v-toolbar-title>
          </v-toolbar>
        </template> -->
        <template v-slot:[`item.data`]="{ item }">
          {{ item.dataPagamento | localDateToString }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-switch
            v-model="item.pago"
            color="success darken-2"
            @change="pagar(item)"
            small
            hide-details
            style="margin: 5px"
          >
          </v-switch>
        </template>
        <template v-slot:[`item.valor`]="{ item }">
          {{ item.valor | formataMoeda }}
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="pageable.page"
          :length="pageable.totalPages"
          @input="changePage"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import FormaPagmento from "@/service/parcelaPagamentoProjeto";
import moment from "moment";
export default {
  name: "Periodo",
  data() {
    return {
      loading: false,
      pago: true,
      form: {
        dataInicio: "",
        dataFim: "",
      },
      rules: {
        required: (value) => !!value || "Obrigatório",
      },
      contas: [],
      headers: [
        {
          text: "Projeto",
          value: "nomeProjeto",
          align: "start",
          filterable: false,
          sortable: false,
        },
        {
          text: "Cliente",
          value: "nomeCliente",
          filterable: false,
          sortable: false,
        },

        {
          text: "Forma pagamento",
          value: "tipoPagamento",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Data",
          value: "data",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Valor",
          value: "valor",
          filterabldatae: false,
          sortable: false,
        },
        {
          text: "Pago",
          value: "actions",
          filterabldatae: false,
          sortable: false,
        },
      ],
      options: {},
      pageable: {},
    };
  },

  methods: {
    changePage(value) {
      console.log(value);
      this.pageable.page = value;
      this.buscar({ page: value - 1 });
    },
    async buscar(options) {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      const payload = {
        dataInicio: moment(this.form.dataInicio, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        dataFim: moment(this.form.dataFim, "DD/MM/YYYY").format("YYYY-MM-DD"),
        ...options,
      };
      try {
        const resp = await FormaPagmento.fetchParcelasPagar(payload);
        this.contas = resp.content;
        this.pageable = {
          totalElements: resp.totalElements,
          totalPages: resp.totalPages,
          pageSize: resp.size,
          page: resp.number + 1,
        };
        console.log(this.pageable);
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      this.loading = false;
    },
    async pagar(item) {
      if (!item.parcelaId) return;

      const payload = {
        id: item.parcelaId,
        pago: item.pago,
      };
      try {
        await FormaPagmento.pagarParcela({ ...payload });

        if (item.pago) {
          this.$notify({
            title: "Sucesso",
            text: "Parcela paga!",
            type: "success",
          });
        }
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: "Desculpe, ocorreu um erro no sistema!",
          type: "error",
        });
        item.pago = false;
      }
    },
  },
};
</script>

<style></style>
